<h3 class="page-label">User Profile</h3>
<div class="row">
  <div class="col-lg-12 grid-margin">
    <div class="card">
      <div class="card-body col-md-12 row">
          <div class="col-md-8">
            <div class="row mb-2">
              <h4 class="col-md-3">User Name:</h4>
              <div class="col-md-6">{{userName}}</div>
            </div>
            <div class="row mb-2">
                <h4 class="col-md-3">Email:</h4>
                <div class="col-md-6">{{email}}</div>
              </div>
          </div>
      </div>
    </div>
  </div>
</div>