import { NgModule, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './button/button.component';
import { DataTableComponent } from './data-table/data-table.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { OrderModule } from 'ngx-order-pipe';
import { ModalComponent } from './modal/modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DeleteModalComponent } from './delete-modal/delete-modal.component';
import { DocumentListComponent } from './document-list/document-list.component';
import { DocumentAddComponent } from './document-add/document-add.component';
import { ExcelService } from './excel.service';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { QRCodeComponent, QRCodeModule } from 'angularx-qrcode';


@NgModule({
  declarations: [
    ButtonComponent,
    DataTableComponent,
    ModalComponent,
    DeleteModalComponent,
    DocumentListComponent,
    DocumentAddComponent,
    UserProfileComponent,
  ],
  imports: [
    CommonModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    OrderModule,
    FormsModule,
    NgMultiSelectDropDownModule,
    NgbModule,
    ReactiveFormsModule,
    QRCodeModule
  ],
  exports:[
    ButtonComponent,
    DataTableComponent,
    ModalComponent,
    DocumentListComponent,
    NgMultiSelectDropDownModule,
    DocumentAddComponent,
    QRCodeComponent
  ],
  providers:[NgbActiveModal, ExcelService],
  entryComponents:[]
})
export class UiComponentsModule { 

}
