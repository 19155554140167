import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-embed',
  templateUrl: './embed.component.html',
  styleUrls: ['./embed.component.scss']
})
export class EmbedComponent {

  safeSrc: SafeResourceUrl;
  constructor(private activatedRoute: ActivatedRoute,private sanitizer: DomSanitizer) { 
    this.activatedRoute.params.subscribe(params => {

      const url = "https://app.powerbi.com/reportEmbed?reportId=" + params['reportId'] + "&appId=" + params['appId'] + "&autoAuth=" + params['autoAuth'] +  "&ctid=" + params['ctid']
      this.safeSrc =  this.sanitizer.bypassSecurityTrustResourceUrl(url)
   });

  }

}
