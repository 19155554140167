<div class="stepper">
    <!-- STEPPER HEADER -->
    <div class="stepper-header">
        <div
        *ngFor="let step of steps; let i = index"
        [class.active]="currentStep === i"
        (click)="setStep(i)"
        >
        {{ step }}
        </div>
    </div>

    <div class="stepper-content">
        
        <div *ngIf="currentStep === 0">

            <div class="row">
                <div class="col-lg-3 stretch-card grid-margin" *ngFor="let app of apps; let rowIndex = index">
                    <div class="card card-img-holder text-white"> 
                        <a href={{app.url}} title="{{app.toolTip}}"  >   
                            <div class="card-body" >
                                <img src="../assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image">
                                <h4 class="font-weight-normal mb-3" style="color:black">{{ app.name }} <i style="font-size: 32px;" class="mdi {{app.icon}} mdi-24px float-end"></i>
                                </h4>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

        </div>

        <div *ngIf="currentStep === 1">
            <!-- <div class="row" style="margin-top: 30px;">
                <div _ngcontent-bil-c75="" class="page-header">
                    <h3 _ngcontent-bil-c75="" class="page-title">Enterprise</h3>
                </div>
            </div> -->
            
            <div class="row">
                <div class="col-lg-3 stretch-card grid-margin" *ngFor="let app of enterprise; let rowIndex = index">
                    <div class="card card-img-holder text-white"> 
                        <a href={{app.url}} title="{{app.toolTip}}"  >         
                            <div class="card-body" >
                                <img src="../assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image">
                                <h4 class="font-weight-normal mb-3" style="color:black">{{ app.name }} <i style="font-size: 32px;color:white" class="mdi {{app.icon}} mdi-24px float-end"></i>
                                </h4>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>


        <div *ngIf="currentStep === 2">
            <!-- <div class="row" style="margin-top: 30px;">
                <div _ngcontent-bil-c75="" class="page-header">
                    <h3 _ngcontent-bil-c75="" class="page-title">Analysis</h3>
                </div>
            </div> -->
            
            <div class="row">
                <div class="col-lg-3 stretch-card grid-margin" *ngFor="let app of analysis; let rowIndex = index">
                    <div class="card card-img-holder text-white"> 
                        <a href={{app.url}} title="{{app.toolTip}}"  >         
                            <div class="card-body" >
                                <img src="../assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image">
                                <h4 class="font-weight-normal mb-3" style="color:black">{{ app.name }} <i style="font-size: 32px;" class="mdi {{app.icon}} mdi-24px float-end"></i>
                                </h4>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>


        <div *ngIf="currentStep === 3">
            <!-- <div class="row" style="margin-top: 30px;">
                <div _ngcontent-bil-c75="" class="page-header">
                    <h3 _ngcontent-bil-c75="" class="page-title">Lean</h3>
                </div>
            </div> -->
            
            <div class="row">
                <div class="col-lg-3 stretch-card grid-margin" *ngFor="let app of lean; let rowIndex = index">
                    <div class="card card-img-holder text-white"> 
                        <a href={{app.url}} title="{{app.toolTip}}"  >         
                            <div class="card-body" >
                                <img src="../assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image">
                                <h4 class="font-weight-normal mb-3" style="color:black">{{ app.name }} <i style="font-size: 32px;" class="mdi {{app.icon}} mdi-24px float-end"></i>
                                </h4>
                            </div>
                        </a>
                    </div>
                </div>
            </div>        
        </div>
    
    </div>

</div>

