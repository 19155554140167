<nav class="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row" >
  <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
    <a class="navbar-brand brand-logo" routerLink="/">Apps <small>{{env}}</small></a>
    <a class="navbar-brand brand-logo-mini" routerLink="/">mApps</a>
  </div>
  <div class="navbar-menu-wrapper d-flex align-items-stretch">

    <div class="search-field d-none d-md-block">
      <form class="d-flex align-items-center h-100" action="#">
        <div class="input-group">
          <!-- <div class="input-group-prepend bg-transparent">
            <i class="input-group-text border-0 mdi mdi-magnify"></i>
          </div>
          <input type="text" class="form-control bg-transparent border-0" placeholder="Search tags"> -->
        </div>
      </form>
    </div>
    <ul class="navbar-nav navbar-nav-right">
      <li class="nav-item nav-profile dropdown" ngbDropdown>
        <a class="nav-link dropdown-toggle" id="profileDropdown" ngbDropdownToggle>
          <div class="nav-profile-img">
            <img src="{{userImage$ | async}}" onerror="this.src='assets/img/user.jpg'" alt="image">
            <span class="availability-status online"></span>

          </div>
          <div class="nav-profile-text">
            <div *ngIf="username !== undefined">
              <p class="mb-1 text-black">{{username}}</p>
            </div>

          </div>
        </a>
        <div class="dropdown-menu navbar-dropdown" ngbDropdownMenu aria-labelledby="profileDropdown">

          <a class="dropdown-item" href="https://admin.{{urlPrefix}}.marmonim.com" >
            <i class="mdi mdi-account mr-2 text-success"></i>
            Profile
          </a>
          <a class="dropdown-item" href="https://admin.{{urlPrefix}}.marmonim.com/request/access" >
            <i class="mdi mdi-account-lock mr-2 text-success"></i>
            Request Access
          </a>

          <a class="dropdown-item" routerLink="/about" routerLinkActive="active" #profileEdit="routerLinkActive">
            <i class="mdi mdi-frequently-asked-questions mr-2 text-success"></i>
            FAQ
          </a>
          
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="javascript:;" (click)="logout()">
            <i class="mdi mdi-logout mr-2 text-primary"></i>
            Signout
          </a>
        </div>
      </li>
      
      
    </ul>
  </div>
</nav>