import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DataService } from '../data.service';

const URL_PREFIX = environment.URL_PREFIX

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {

  apps = [];
  analysis = [];
  enterprise = [];
  lean = [];

  steps = ['IoT', 'Enterprise', 'Analysis', 'Lean'];
  currentStep = 0;

  constructor( private service: DataService) { }
  
  ngOnInit() {
    this.getAllApps();
  }

  rows: number[] = [];

  getRows(apps: any) {
    const numberOfRows = Math.ceil(apps.length / 4);
    return Array(numberOfRows).fill(0).map((_, i) => i);
  }
  
  getAppsForRow(apps: any, rowIndex: number): any[] {
    const startIndex = rowIndex * 4;
    const endIndex = startIndex + 4;
    const appsForRow = apps.slice(startIndex, endIndex);

    // Add placeholders for empty cells
    while (appsForRow.length < 4) {
      appsForRow.push(null);
    }

    console.log(appsForRow)
    return appsForRow;
  }

  // 0 - Apps
  // 1 - Enterprise
  // 2 - Analysis
  // 3 - Lean

  getAllApps(){

    this.service.getApps().subscribe((res: any) => {
      
      for(var app of res.data) {
        if (['APP-000001', 'APP-000002'].includes(app.AppId)) {
          continue
        }

        const url = app.Url != null ? app.Url : `https://${app.UrlPrefix}.${URL_PREFIX}.marmonim.com`

        if ( app.Category == 0) {
          this.apps.push({
            name: app.Name, 
            icon: app.Icon, 
            url: url,
            toolTip: app.ShortDescription})
        } else if ( app.Category == 1) {
          this.enterprise.push({
            name: app.Name, 
            icon: app.Icon, 
            url: url,
            toolTip: app.ShortDescription})
        } else if ( app.Category == 2) {
          this.analysis.push({
            name: app.Name, 
            icon: app.Icon, 
            url: url,
            toolTip: app.ShortDescription})
        } else if ( app.Category == 3) {
          this.lean.push({
            name: app.Name, 
            icon: app.Icon, 
            url: url,
            toolTip: app.ShortDescription})
        }
      }
    })

  }

  setStep(index: number): void {
    this.currentStep = index;
    
  }
}
