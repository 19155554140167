import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

const API_URL = environment.API_URL;

const httpOptions = {
  headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*'
  })
};

@Injectable({
  providedIn: 'root'
})

export class DataService {
  data: Object = {};

  private dataSub = new BehaviorSubject<object>(this.data);
  currentData = this.dataSub.asObservable();

  constructor(private http: HttpClient) {}

  changeDataSub(newData: object) {
      this.dataSub.next(newData); 
  }

   // Returns a list of all locations and apps permission that for logged in user
   getApps(): Observable<any> {
    return this.http.get(API_URL + '/admin/appnames', httpOptions);
  }
}
