

<div class="card-container">
<ng-container *ngIf="(isAuthenticated$ | async) === false; else signout">
  <!-- <button (click)="signIn()">Login</button>  -->
  <app-login></app-login>
 </ng-container> 
<!-- <ng-template #signout>
  <div>"YES"</div>
</ng-template> -->
<!-- <button *ngIf="(isAuthenticated$ | async) === true;" (click)="signOut()">Logout</button> -->
<ng-template class="container-scroller" #signout>
  <app-navbar *ngIf="showNavbar"></app-navbar>
  <div class="container-fluid page-body-wrapper">
    <app-sidebar *ngIf="showSidebar"></app-sidebar>
    <div class="main-panel" [ngClass]="{ 'main-panel-only' : !showSidebar }" style="width:100% !important">
      <div class="content-wrapper">
        <app-spinner *ngIf="isLoading"></app-spinner>
        <div contentAnimate *ngIf="!isLoading" class="h-100">
          <router-outlet></router-outlet>
          <notifier-container></notifier-container>          
        </div>
      </div>
      <app-footer *ngIf="showFooter"></app-footer>
    <!--  partial -->
    </div>
    <!-- main-panel ends -->
</div> 
  <!-- page-body-wrapper ends -->
 </ng-template> 

</div>
