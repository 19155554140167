import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  constructor() { }

  email: any;
  userName: any;

  ngOnInit(): void {
    this.email = localStorage.getItem('email')
    this.userName = localStorage.getItem('loggedInUserName')
  }

}
