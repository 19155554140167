<div class="container">
    <div class="alert alert-warning" role="alert">
      <h1 class="alert-heading">Under Construction</h1>
    </div>
  </div>

  <div class="container">
    <h2 class="mt-5">Observation Form</h2>

      <div class="mb-3">
        <label for="observation" class="form-label">Observation:</label>
        <input type="text" class="form-control" id="observation">
      </div>
      
      <div class="row">
        <div class="col-md-6 ">
          <h3>7 Wastes</h3>
          <ul class="list-group">
              <li class="list-group-item"> <input class="form-check-input" type="checkbox" value="" id="overProduction">Over Production</li>
              <li class="list-group-item"> <input class="form-check-input" type="checkbox" value="" id="overProduction">Transportation</li>
              <li class="list-group-item"> <input class="form-check-input" type="checkbox" value="" id="overProduction">Motion</li>
              <li class="list-group-item"> <input class="form-check-input" type="checkbox" value="" id="overProduction">Waiting</li>
              <li class="list-group-item"> <input class="form-check-input" type="checkbox" value="" id="overProduction">Processing</li>
              <li class="list-group-item"> <input class="form-check-input" type="checkbox" value="" id="overProduction">Inventory</li>
              <li class="list-group-item"> <input class="form-check-input" type="checkbox" value="" id="overProduction">Defects</li>
          </ul>

        </div>

        <div class="col-md-6 ">
          <h3>Facility</h3>
          <ul class="list-group">
              <li class="list-group-item"> <input class="form-check-input" type="checkbox" value="" id="overProduction">Safety</li>
              <li class="list-group-item"> <input class="form-check-input" type="checkbox" value="" id="overProduction">Environmental</li>
              <li class="list-group-item"> <input class="form-check-input" type="checkbox" value="" id="overProduction">Space</li>
              <li class="list-group-item"> <input class="form-check-input" type="checkbox" value="" id="overProduction">Energy</li>
          </ul>
        </div>
    </div>
    <br/>
    <div class="row">

      <div class="col-md-12 ">
        <button type="submit" class="btn btn-primary">Save</button>
        <button type="submit" class="btn btn-primary">Save & Add Another</button>
        <button type="reset" class="btn btn-secondary">Cancel</button>
        <button type="button" class="btn btn-info">Review</button>
      </div>
    </div>

  </div>