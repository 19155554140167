<div class="content-wrapper">
    <div class="page-header">
        <h3 class="page-title"> FAQ </h3>
        <nav aria-label="breadcrumb">
            <!-- <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#">Sample pages</a></li>
          <li class="breadcrumb-item active" aria-current="page">FAQ</li>
        </ol> -->
        </nav>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="faq-section">
                        <div class="container-fluid bg-gradient-marmon py-2">
                            <p class="mb-0 text-white">Assets</p>
                        </div>
                        <div id="accordion-1" class="accordion">
                            <div class="card">
                                <div class="card-header" id="headingOne">
                                    <h5 class="mb-0">
                                        <a data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true"
                                            aria-controls="collapseOne">
                                            The Assets application is a robust and user-friendly database designed to
                                            catalog and manage all assets within a manufacturing company. This tool is
                                            essential for maintaining an organized and efficient record of all
                                            machinery, equipment, and other vital assets.
                                        </a>
                                    </h5>
                                </div>
                                <!-- <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-bs-parent="#accordion-1">
                    <div class="card-body"> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS. </div>
                  </div> -->
                            </div>

                        </div>
                    </div>

                    <div class="faq-section">
                        <div class="container-fluid bg-gradient-marmon py-2">
                            <p class="mb-0 text-white">Sites</p>
                        </div>
                        <div id="accordion-1" class="accordion">
                            <div class="card">
                                <div class="card-header" id="headingOne">
                                    <h5 class="mb-0">
                                        <a data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true"
                                            aria-controls="collapseOne">
                                            Sites is a comprehensive directory application designed to provide users
                                            with detailed information about companies and their respective locations.
                                        </a>
                                    </h5>
                                </div>
                                <!-- <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-bs-parent="#accordion-1">
                      <div class="card-body"> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS. </div>
                    </div> -->
                            </div>

                        </div>
                    </div>

                    <div class="faq-section">
                        <div class="container-fluid bg-gradient-marmon py-2">
                            <p class="mb-0 text-white">Downtime</p>
                        </div>
                        <div id="accordion-1" class="accordion">
                            <div class="card">
                                <div class="card-header" id="headingOne">
                                    <h5 class="mb-0">
                                        <a data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true"
                                            aria-controls="collapseOne">
                                            Downtime is an essential tool for manufacturing companies, designed to
                                            simplify and streamline the reporting and management of machine downtimes.
                                            By providing a seamless way to document downtime events and their causes,
                                            Downtime helps you minimize production interruptions and optimize
                                            operational efficiency.
                                        </a>
                                    </h5>
                                </div>
                                <!-- <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-bs-parent="#accordion-1">
                      <div class="card-body"> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS. </div>
                    </div> -->
                            </div>

                        </div>
                    </div>

                    <div class="faq-section">
                        <div class="container-fluid bg-gradient-marmon py-2">
                            <p class="mb-0 text-white">Scheduler</p>
                        </div>
                        <div id="accordion-1" class="accordion">
                            <div class="card">
                                <div class="card-header" id="headingOne">
                                    <h5 class="mb-0">
                                        <a data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true"
                                            aria-controls="collapseOne">
                                            The Scheduler is a powerful and intuitive application designed to optimize
                                            the scheduling of machine run times and downtimes, including breaks and
                                            maintenance periods, for all assets within a factory. This application is
                                            tailored to enhance operational efficiency, reduce downtime, and ensure
                                            smooth production workflows.
                                        </a>
                                    </h5>
                                </div>
                                <!-- <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-bs-parent="#accordion-1">
                      <div class="card-body"> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS. </div>
                    </div> -->
                            </div>

                        </div>
                    </div>

                    <div class="faq-section">
                        <div class="container-fluid bg-gradient-marmon py-2">
                            <p class="mb-0 text-white">Tags</p>
                        </div>
                        <div id="accordion-1" class="accordion">
                            <div class="card">
                                <div class="card-header" id="headingOne">
                                    <h5 class="mb-0">
                                        <a data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true"
                                            aria-controls="collapseOne">
                                            The Tags Application is a comprehensive tool designed to standardize and
                                            manage the tags output by manufacturing machines from Programmable Logic
                                            Controllers (PLCs). This application ensures consistency and clarity across
                                            your manufacturing operations by collating all machine-generated tags into a
                                            centralized database. Each tag is meticulously standardized in terms of name
                                            and meaning, facilitating seamless integration, monitoring, and analysis of
                                            machine performance data.
                                        </a>
                                    </h5>
                                </div>
                                <!-- <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-bs-parent="#accordion-1">
                      <div class="card-body"> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS. </div>
                    </div> -->
                            </div>

                        </div>
                    </div>

                    <div class="faq-section">
                        <div class="container-fluid bg-gradient-marmon py-2">
                            <p class="mb-0 text-white">Unit of Measure</p>
                        </div>
                        <div id="accordion-1" class="accordion">
                            <div class="card">
                                <div class="card-header" id="headingOne">
                                    <h5 class="mb-0">
                                        <a data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true"
                                            aria-controls="collapseOne">
                                            The Unit of Measure Application serves as a comprehensive repository
                                            encompassing all globally standardized units of measurement. Designed to
                                            streamline and harmonize data across diverse industries and applications,
                                            this centralized platform ensures consistency and accuracy in quantifying
                                            and comparing various parameters.
                                        </a>
                                    </h5>
                                </div>
                                <!-- <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-bs-parent="#accordion-1">
                      <div class="card-body"> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS. </div>
                    </div> -->
                            </div>

                        </div>
                    </div>

                    <div class="faq-section">
                        <div class="container-fluid bg-gradient-marmon py-2">
                            <p class="mb-0 text-white">ERPT</p>
                        </div>
                        <div id="accordion-1" class="accordion">
                            <div class="card">
                                <div class="card-header" id="headingOne">
                                    <h5 class="mb-0">
                                        <a data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true"
                                            aria-controls="collapseOne">TBD
                                        </a>
                                    </h5>
                                </div>
                                <!-- <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-bs-parent="#accordion-1">
                      <div class="card-body"> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS. </div>
                    </div> -->
                            </div>

                        </div>
                    </div>

                    <div class="faq-section">
                        <div class="container-fluid bg-gradient-marmon py-2">
                            <p class="mb-0 text-white">Stand in the circle</p>
                        </div>
                        <div id="accordion-1" class="accordion">
                            <div class="card">
                                <div class="card-header" id="headingOne">
                                    <h5 class="mb-0">
                                        <a data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true"
                                            aria-controls="collapseOne">TBD
                                        </a>
                                    </h5>
                                </div>
                                <!-- <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-bs-parent="#accordion-1">
                      <div class="card-body"> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS. </div>
                    </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>