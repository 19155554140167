import { Component, Inject, OnInit } from '@angular/core';
import { Router, NavigationStart, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})


export class AppComponent implements OnInit{
  title = 'AssetUI';
  public isAuthenticated$!: Observable<boolean>;
  isAuthenticated: boolean = false;
  
  showSidebar: boolean = true;
  showNavbar: boolean = true;
  showFooter: boolean = true;
  isLoading: boolean;



  constructor(private router: Router,private _oktaStateService: OktaAuthStateService, @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth) {
    // Removing Sidebar, Navbar, Footer for Documentation, Error and Auth pages
    this.router.events.forEach((event) => { 
      if(event instanceof NavigationStart) {
        console.log(event['url']);
        if((event['url'] == '/user-pages/register') || (event['url'] == '/error-pages/404') || (event['url'] == '/error-pages/500') ) {
          this.showSidebar = false;
          this.showNavbar = false;
          this.showFooter = false;
         
          document.querySelector('.main-panel').classList.add('w-100');
          document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
          document.querySelector('.content-wrapper').classList.remove('auth', 'auth-img-bg', );
          document.querySelector('.content-wrapper').classList.remove('auth', 'lock-full-bg');
          if((event['url'] == '/error-pages/404') || (event['url'] == '/error-pages/500')) {
            document.querySelector('.content-wrapper').classList.add('p-0');
          }
        } else if((event['url'] == '/trr') || (event['url'] == '/dashboard') || (event['url'] == '/template-upload') || (event['url'] == '/erpt') || ((event['url']).includes('/change-requests/'))){
          this.showSidebar = false;
          this.showNavbar = true;
          this.showFooter = true;
        } else {
          this.showSidebar = false;
          this.showNavbar = true;
          this.showFooter = true;
          // document.querySelector('.main-panel').classList.remove('w-100');
          // document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
          // document.querySelector('.content-wrapper').classList.remove('auth', 'auth-img-bg');
          // document.querySelector('.content-wrapper').classList.remove('p-0');
        }
      }
    });

    // Spinner for lazyload modules
    router.events.forEach((event) => { 
      if (event instanceof RouteConfigLoadStart) {
          this.isLoading = true;
      } else if (event instanceof RouteConfigLoadEnd) {
          this.isLoading = false;
      }
    });
  }


  public ngOnInit(): void {
    // this._oktaStateService.authState$.subscribe(
    //   (res: any) => {
    //     this.isAuthenticated = res.isAuthenticated;
    //     console.log('Is authenticated:', res);
    //   }
    // );
    // this.isAuthenticated$ = this._oktaStateService.authState$.pipe(
    //   filter((s: AuthState) => !!s),
    //   map((s: AuthState) => s.isAuthenticated ?? false)
    // );
    // this.isAuthenticated$.subscribe(isAuthenticated => {
    //   this.isAuthenticated = isAuthenticated;
    // });
    // console.log(this.isAuthenticated);
  }

  public async signIn() : Promise<void> {
    await this._oktaAuth.signInWithRedirect();
  }

  public async signOut(): Promise<void> {
    await this._oktaAuth.signOut();
  }
    // // Scroll to top after route change
    // this.router.events.subscribe((evt) => {
    //   if (!(evt instanceof NavigationEnd)) {
    //       return;
    //   }
    //   window.scrollTo(0, 0);
    // });

 
}
