import { Component, Inject, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { Observable, filter, map } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import OktaAuth, { AuthState } from '@okta/okta-auth-js';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavbarComponent implements OnInit {
  userImage$: Observable<string>;
  public iconOnlyToggled = false;
  public sidebarToggled = false;
  username: any;
  editUrl: '/profile';
  acc: any;
  isSiderBar: boolean = false;
  public isAuthenticated$!: Observable<boolean>;
  isAuthenticated: boolean = false;

  accessToken: string | undefined;
  userInfo: any;
  userEmail: any;
  idToken: any;
  urlPrefix = environment.URL_PREFIX;
  env = environment.type != "prod" ? environment.type : ""

  constructor(config: NgbDropdownConfig, private http: HttpClient, private router: Router, private _oktaStateService: OktaAuthStateService,
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth) {
    this.accessToken = this._oktaAuth.getAccessToken();

    localStorage.setItem('accessToken', this.accessToken);

    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {

        console.log(event['url']);
        if ((event['url'] == '/trr') || (event['url'] == '/dashboard') || (event['url'] == '/template-upload') || (event['url'] == '/erpt') || ((event['url']).includes('/change-requests/'))) {
          this.isSiderBar = false;
        }
      }
    });
    config.placement = 'bottom-right';

    this.getAccountInfo();

  }

  async getAccountInfo() {
    this.userInfo = await this._oktaAuth.getUser();

    if (this.userInfo) {
      console.log(this.userInfo);
      this.username = this.userInfo.name;

      localStorage.setItem("email", this.userInfo.email);
      localStorage.setItem("loggedInUserName", this.username);
    } else {
      console.error('ID token not found.');
    }
  }

  checkUrlSubstring(substring: string): boolean {
    const currentUrl = this.router.url;
    return currentUrl.includes(substring);
  }

  ngOnInit() {
    // setTimeout(() => {
    //   var name = localStorage.getItem('loggedInUserName').split(',')[1];
    //   this.username = name;
    // }, 4000);
  }

  // toggle sidebar in small devices
  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }

  // toggle sidebar
  toggleSidebar() {
    let body = document.querySelector('body');
    if ((!body.classList.contains('sidebar-toggle-display')) && (!body.classList.contains('sidebar-absolute'))) {
      this.iconOnlyToggled = !this.iconOnlyToggled;
      if (this.iconOnlyToggled) {
        body.classList.add('sidebar-icon-only');
      } else {
        body.classList.remove('sidebar-icon-only');
      }
    } else {
      this.sidebarToggled = !this.sidebarToggled;
      if (this.sidebarToggled) {
        body.classList.add('sidebar-hidden');
      } else {
        body.classList.remove('sidebar-hidden');
      }
    }
  }

  logout(): void {
    this._oktaAuth.signOut();
  }

}
