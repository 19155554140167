import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UserProfileComponent } from './shared/ui-components/user-profile/user-profile.component';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { EmbedComponent } from './embed/embed.component'
import { UnderConstructionComponent } from './under-construction/under-construction.component'
import { AboutComponent } from './about/about.component';

const routes: Routes = [
  { 
    path: '', 
    canActivate:[OktaAuthGuard],
    redirectTo:'dashboard'
  },
  { path: 'login/callback', component: OktaCallbackComponent },
  { 
    path: 'user/profile', 
    canActivate:[OktaAuthGuard],
    component: UserProfileComponent,
  }, 
  { 
    path: 'embed/reportId/:reportId/appId/:appId/autoAuth/:autoAuth/ctid/:ctid', 
    canActivate:[OktaAuthGuard],
    component: EmbedComponent,
  }, 
  { 
    path: 'under/construction', 
    canActivate:[OktaAuthGuard],
    component: UnderConstructionComponent
  },
  { 
    path: 'about', 
    canActivate:[OktaAuthGuard],
    component: AboutComponent
  },
  { 
    path: '', 
    canActivate:[OktaAuthGuard],
    component: DashboardComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
  })],
  exports: [RouterModule]
})

export class AppRoutingModule {
}