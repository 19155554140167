import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  isLoggedIn = false;
  authUserEmail: any;
  loggedInCustomer: any;

  constructor( private router: Router,  @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth) { 
  }

  ngOnInit(): void {
  }

  public async signIn() : Promise<void> {
    await this._oktaAuth.signInWithRedirect();
  }
}
