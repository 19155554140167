import { Component, OnInit } from '@angular/core';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public companyCollapsed = false;
  public assetCollapsed = false;
  public employeeCollapsed = false;
  public samplePagesCollapsed = false;
  public branchCollapsed = false;
  public userCollapsed = false;
  public userRoleCollapsed = false;
  public alertCollapsed = false;
  public locationCollapsed = false;

  constructor() { }

  ngOnInit() {
    const body = document.querySelector('body');

    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    document.querySelectorAll('.sidebar .nav-item').forEach(function (el) {
      el.addEventListener('mouseover', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
         // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
         document.querySelectorAll('.sidebar .nav-item active').forEach(function (el) {
          el.addEventListener('mouseover', function() {
            if(body.classList.contains('sidebar-icon-only')) {
              el.classList.add('hover-open');
            }
          });
          el.addEventListener('mouseout', function() {
            if(body.classList.contains('sidebar-icon-only')) {
              el.classList.remove('hover-open');
            }
          });
        });

  }

}
